import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomLink from "../components/customLink"
import { updateImgixParams } from "../components/_helpers/updateImgixParams"

const SolutionsPage = () => {
  let data = useStaticQuery(graphql`
    query SolutionsPageQuery {
      prismicSolutionsPage {
        data {
          hero_image {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          hero_title {
            text
            html
          }
          solutions_headline {
            text
            html
          }
          solutions {
            solution_description {
              text
              html
            }
            solution_icon {
              fixed(height: 42, width: 42) {
                ...GatsbyPrismicImageFixed
              }
            }
            solution_image {
              fixed(width: 571, height: 571) {
                ...GatsbyPrismicImageFixed
              }
            }
            solution_link {
              link_type
              uid
              type
              url
              target
            }
            solution_link_text {
              text
              html
            }
            solution_name {
              text
              html
            }
            solution_headline {
              text
              html
            }
          }
        }
      }
    }
  `).prismicSolutionsPage.data
  if (!data) return null
  
  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicSolutionsPage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicSolutionsPage.data
  }

  return (
    <Layout>
      <SEO title="Solutions" />

      <section className="solutions-hero hero two">
        <div className="row">
          <div className="inner">
            <div className="col text" data-sal="slide-up">
              <h3>{data.hero_title.text}</h3>

              {data.solutions.map((solution, ind) => {
                if (!solution.solution_name) return null

                return <div className="solution" key={`hero-solution-${ind}`} data-sal="slide-up" data-sal-delay={`${(ind + 3) * 100}`}>
                  <div className="icon">
                    <Img
                      fixed={solution.solution_icon.fixed}
                      imgStyle={{ objectFit: "contain" }}
                      alt={solution.solution_name.text}
                    />
                  </div>
                  
                  <p>{solution.solution_name.text}</p>
                </div>
              })}
            </div>

            <div className="col asset" data-sal="slide-left" data-sal-delay="300">
              <Img fluid={data.hero_image.fluid} alt={data.hero_title.text} />
            </div>
          </div>
        </div>

        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      <section className="solutions-list">
        <div className="inner">
          <h3 data-sal="slide-up" data-sal-delay="300">{data.solutions_headline.text}</h3>

          {data.solutions.map((solution, ind) => {
            if (!solution.solution_name) return null
            
            return <div className="solution" key={`solution-${ind}`}>
              <div className="row">
                <div className="col asset" data-sal="slide-up" data-sal-delay={`${ind++ * 150}`}>
                  <Img fixed={updateImgixParams(solution.solution_image.fixed, {fit: 'crop'})} alt={solution.solution_name.text} />
                </div>

                <div className="col text" data-sal="slide-up" data-sal-delay={`${ind++ * 300}`}>
                  <Img fixed={solution.solution_icon.fixed} alt={solution.solution_name.text} />
                  <h5>{solution.solution_name.text}</h5>
                  <h6>{solution.solution_headline.text}</h6>
                  <div dangerouslySetInnerHTML={{__html: solution.solution_description.html}}></div>
                  
                  {solution.solution_link &&
                    <CustomLink link={solution.solution_link} className="text violet" target={solution.solution_link.target}>{solution.solution_link_text.text}</CustomLink>
                  }
                </div>
              </div>
              <div className="row spacer" data-sal="slide-down" data-sal-delay="300"><span></span></div>
            </div>
          })}
        </div>
      </section>
    </Layout>
  )
}

export default SolutionsPage
